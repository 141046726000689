import React, { useEffect, useState } from "react";
import "../App.css";
import "../sass/style.css";
import isotipo_negro from "../images/isotipo-negro.svg";
import logo_saturn from "../images/logo_saturn.svg";
import logo_morado from "../images/logo_morado.svg";
import { Link } from "react-router-dom";
import { obtEmpresaUrl } from "../utils/global_functions";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Header cuando el usuario no está logueado ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->

export default function Header() {
  const [logo, setLogo] = useState("");
  const [logoSecundario, setLogoSecundario] = useState("");
  
  useEffect(() => {
    async function obtEmpresaConfig(){
      const config = await obtEmpresaUrl();
      setLogo(config.configuracion?.img == undefined ? "" : config.configuracion.img);
      setLogoSecundario(config.confiAdicional?.logo_adicional == undefined ? "" : config.confiAdicional.logo_adicional);
    }

    obtEmpresaConfig();
  }, []);

  return (
    <>
      <div className="header_responsive">
        <Link to="/" className="isotipo">
          {" "}
          <img src={logo_morado} alt="saturn 5" />{" "}
        </Link>
        <Link to="/calculadora" className="link">
          {" "}
          Simula tu crédito{" "}
        </Link>
        <Link to="/" className="link">
          {" "}
          Iniciar Sesión{" "}
        </Link>
      </div>

      <header>
        <div>
          <Link to="/" className={"isotipo"}>
            {" "}
            <img src={logo} alt="logo" />{" "}
          </Link>

          <div className="redes-saturn">
            <ul className="redes">
              <li>
                {" "}
                <a
                  href="https://www.instagram.com/saturn5mx/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-instagram"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a
                  href="https://www.facebook.com/saturn5mx"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-facebook"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a
                  href="https://www.linkedin.com/company/saturn5/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-linkedin"></i>{" "}
                </a>{" "}
              </li>
            </ul>
          </div>

          <div className="redes-remax">
            <ul className="redes">
              <li>
                <a
                  href="https://www.instagram.com/remaxmex/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/RemaxMex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@remaxmexico/featured"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/remaxmexico"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>

          {/* Redes de Consultores */}

          <div className="redes-Consultores">
            <ul className="redes">
              <li>
                <a
                  href="https://www.instagram.com/mymconsultores?igsh=OWFkc21laHV2Y3A1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/myminmuebles/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/mymconsultoresinmobiliarios/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="redes-archandel">
            <ul className="redes">
              <li>
                {" "}
                <a
                  href="https://www.instagram.com/archandelrealestate/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-instagram"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a
                  href="https://www.facebook.com/archandelrealestate/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-facebook"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a
                  href="https://www.linkedin.com/company/archandel/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-linkedin"></i>{" "}
                </a>{" "}
              </li>
            </ul>
          </div>

          <div className="redes-opera">
            <ul className="redes">
              <li>
                {" "}
                <a
                  href="https://www.instagram.com/opera.desarrolladora/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-instagram"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a
                  href="https://www.facebook.com/operad/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-facebook"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a
                  href="https://mx.linkedin.com/company/opera-desarrolladora"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="fa fa-linkedin"></i>{" "}
                </a>{" "}
              </li>
            </ul>
          </div>

        </div>
        {
          <Link to="/" className="ocultar_logoSaturn">
            {" "}
            <img src={logoSecundario} alt="" />{" "}
          </Link>
        }
        <ul className="menu ">
          <li className="ocultar_contacto">
            {" "}
            <a
              href="https://saturn5.mx/#section-101-17"
              target="_blank"
              rel="noreferrer"
            >
              Contacto
            </a>{" "}
          </li>
          <li>
            {" "}
            <Link to="/calculadora" className="btn btn_morado">
              {" "}
              Simula tu crédito{" "}
            </Link>{" "}
          </li>
          <li>
            {" "}
            <Link to="/" className="btn btn_bco">
              {" "}
              Login{" "}
            </Link>{" "}
          </li>
        </ul>
      </header>
    </>
  );
}
